
  import Vue from 'vue';
  import messages from './messages.json';

  import webSocketRequest from "@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/index.vue";
  import webSocketImportRequest
    from "@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/web-socket-import-request/index.vue";
  import { WebSocketTaskType } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskType";
  import collapsibleCard from "@/main/webapp/vue/components/ui/collapsible/card/index.vue";

  export default Vue.extend({
    components: {
      collapsibleCard,
      webSocketRequest,
      webSocketImportRequest
    },
    data() {
      return {
        file: null as File | null,
        keepExistingData: true as boolean,
        replaceResponsibles: false as boolean,
        inactivateShops: false as boolean,
        WebSocketTaskType
      };
    },
    methods: {
      confirmDeletion(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`chain-shop-import component - confirmDeletion()`);
          }

          let decision: any = this.$bvModal.msgBoxConfirm(this.$t('confirm-deletion-text').toString());
          Promise.resolve(decision).then(value => {
            if (value) {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`chain-shop-import component - confirmDeletion() - resolve decision`);
                console.log(decision);
              }
              resolve(decision);
            }
          });
        });
      },
      clearForm(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`chain-shop-import component - clearForm()`);
        }

        this.file = null; // If we don't unset the file, the same file is uploaded unless one with different filename is selected
      }
    },
    i18n: {
      messages: messages
    }
  });
