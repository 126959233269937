import { ApiError, apiService } from "./ApiService";

import { AuthenticationResult } from '@/main/webapp/vue/model/api/AuthenticationResult';
import { FeaturedSubmission } from "@/main/webapp/vue/model/api/FeaturedSubmission";
import { Feed } from "@/main/webapp/vue/model/api/Feed";
import { Gallery } from "@/main/webapp/vue/model/api/Gallery";
import { User } from '@/main/webapp/vue/model/api/User';
import { Item } from '@/main/webapp/vue/model/api/Item';
import { ItemLikes } from "@/main/webapp/vue/model/api/ItemLikes";
import { ItemLike } from "@/main/webapp/vue/model/api/ItemLike";
import { ItemComments } from "@/main/webapp/vue/model/api/ItemComments";
import { ItemComment } from "@/main/webapp/vue/model/api/ItemComment";
import { Organization } from "@/main/webapp/vue/model/api/Organization";
import { ThemableComponents } from "@/main/webapp/vue/model/api/ThemableComponents";
import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
import { Shop } from "@/main/webapp/vue/model/api/Shop";
import { UploadConfiguration } from "@/main/webapp/vue/model/api/UploadConfiguration";
import { ShopContainer } from "@/main/webapp/vue/model/api/ShopContainer";
import { ProjectContainer } from "@/main/webapp/vue/model/api/ProjectContainer";
import { CategoryContainer } from "@/main/webapp/vue/model/api/CategoryContainer";
import { GeoLocation } from "@/main/webapp/vue/model/api/GeoLocation";
import { Project } from "@/main/webapp/vue/model/api/Project";
import { ProjectDetails } from "@/main/webapp/vue/model/api/ProjectDetails";
import { ProjectProgressContainer } from "@/main/webapp/vue/model/api/ProjectProgressContainer";
import { SubmissionUploadData } from "@/main/webapp/vue/model/api/SubmissionUploadData";
import { Any } from "json2typescript";
import { BackendError } from "@/main/webapp/vue/model/BackendError";
import { StatusUpdates } from "@/main/webapp/vue/model/api/StatusUpdates";
import { SubmissionContainer } from "@/main/webapp/vue/model/api/SubmissionContainer";
import { SearchResult } from "@/main/webapp/vue/model/api/SearchResult";
import { ThemableComponent } from "@/main/webapp/vue/model/api/ThemableComponent";
import { ItemContentEntity } from "@/main/webapp/vue/model/api/ItemContentEntity";
import { ItemContentEntityContainer } from "@/main/webapp/vue/model/api/ItemContentEntityContainer";
import { UserUpdate } from "@/main/webapp/vue/model/api/UserUpdate";
import { SubmissionQueryCriteria } from "@/main/webapp/vue/model/SubmissionQueryCriteria";
import { CategorySetType } from "@/main/webapp/vue/model/api/Category";
import { oc as Optional } from "ts-optchain";
import { MapSubmissionContainer } from "@/main/webapp/vue/model/api/Map/MapSubmissionContainer";
import { MapShopContainer } from "@/main/webapp/vue/model/api/Map/MapShopContainer";
import { UpdateUserSettingsMetadata } from "@/main/webapp/vue/model/api/UpdateUserSettingsMetadata";
import { UserSettings } from "@/main/webapp/vue/model/api/UserSettings";
import { OrganizationModuleContainer } from "@/main/webapp/vue/model/api/OrganizationModuleContainer";
import { OrganizationModule } from "@/main/webapp/vue/model/api/OrganizationModule";
import { OrganizationModuleFeature } from "@/main/webapp/vue/model/api/OrganizationModuleFeature";
import { Entity } from "@/main/webapp/vue/model/api/Entity";
import { config } from "@/main/webapp/vue/components/map/mapConfiguration";
import { StringValue } from "@/main/webapp/vue/model/api/StringValue";
import { EntityContainer } from "@/main/webapp/vue/model/api/EntityContainer";
import { ShopRegistryClients } from "@/main/webapp/vue/model/api/ShopRegistryClients";
import { ShopRegistryClient } from "@/main/webapp/vue/model/api/ShopRegistryClient";
import { EntityRegistryClientFilter } from "@/main/webapp/vue/model/api/EntityRegistryClientFilter";
import { EntityRegistryClientMapping } from "@/main/webapp/vue/model/api/EntityRegistryClientMapping";
import { EntityRegistryDataSourceEvent } from "@/main/webapp/vue/model/api/EntityRegistryDataSourceEvent";
import { UserRegistryClients } from "@/main/webapp/vue/model/api/UserRegistryClients";
import { EntityRegistryClient } from "@/main/webapp/vue/model/api/EntityRegistryClient";
import { UserRegistryClient } from "@/main/webapp/vue/model/api/UserRegistryClient";
import { ShopDetailsWithMetadata } from "@/main/webapp/vue/model/api/ShopDetailsWithMetadata";
import { ProjectProgress } from "@/main/webapp/vue/model/api/ProjectProgress";
import { ProjectProgressForUserContainer } from "@/main/webapp/vue/model/api/ProjectProgressForUserContainer";
import { SearchCriteria } from "@/main/webapp/vue/model/api/SearchCriteria";
import { SearchCriterion } from "@/main/webapp/vue/model/api/SearchCriterion";
import { SearchCriteriaSelectedDataValue } from "@/main/webapp/vue/model/api/SearchCriteriaSelectedDataValue";
import { ShareItem } from "@/main/webapp/vue/model/api/ShareItem";
import { WebSocketTaskDefinition } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskDefinition";
import { WebSocketTaskDefinitions } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskDefinitions";
import { OrganizationBilling } from "@/main/webapp/vue/model/api/OrganizationBilling";
import {
  ProjectProgressForDepartmentContainer
} from "@/main/webapp/vue/model/api/ProjectProgressForDepartmentContainer";
import { AuditLogEvent } from "@/main/webapp/vue/model/api/AuditLogEvent";

export class BackendIntegrationService {
  public static SUBMISSION_LIST: string = '/items/list';
  public static SHOPS_WITH_DETAILS_SEARCH: string = '/shops/search?details=true&active=false&size=50'
  public static SHOPS_WITH_DETAILS: string = '/shops/details'
  public static CHAINS_WITH_SHOP_COUNTS: string = '/chains/shops/count'

  public static login(username: string, password: string): Promise<AuthenticationResult> {
    return apiService.auth('/login', username, password);
  }

  public static logout(): void {
    apiService.post(Any, '/logout').then((result: Any) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Logout result', result);
      }
    }).catch((error: BackendError) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Logout error', error);
      }
    });
  }

  public static welcomeMessage(userId: number): Promise<Boolean> {
    return apiService.post(Boolean, `/users/${userId}/welcome-message`, null, null, false);
  }

  public static resetPassword(usernameOrEmail: string): Promise<Boolean> {
    return apiService.post(Boolean, '/users/password/reset', {
      userField: usernameOrEmail
    }, null, false);
  }

  /*
   * Link is mandatory as a security measure. Only if the caller has the link, then the feature is available!
   * The backend expects the username as parameter, if already embeded in the link, we don't append the passed value.
   */
  public static assume(username: string, link: NavigationLink): Promise<AuthenticationResult> {
    if ((username === null || username.length === 0) && link.href.indexOf('username') === -1) {
      return new Promise<AuthenticationResult>((resolve, reject) => {
        reject(new Error("Could not assume user, no username provided"));
      });
    }

    return apiService.post(AuthenticationResult, link.href, null,
        link.href.indexOf('username') === -1 ? { 'username': username } : null);
  }

  public static assumeOrganization(link: NavigationLink): Promise<AuthenticationResult> {
    return apiService.post(AuthenticationResult, link.href, null);
  }

  public static unassume(link?: NavigationLink): Promise<AuthenticationResult> {
    return apiService.post(AuthenticationResult, link ? link.href : '/users/unassume');
  }

  public static registerPNToken(token: string): Promise<Boolean> {
    return apiService.post(Boolean, '/push-notifications/web/register', {
      token: token
    }, false);
  }

  public static fetchUserDetails(link?: NavigationLink): Promise<User> {
    return apiService.get(User, link ? link.href : '/users/authenticated/details');
  }

  public static updateUserDetails(userUpdate: UserUpdate): Promise<UserUpdate> {
    return apiService.put(UserUpdate, '/users/authenticated/details', userUpdate);
  }

  public static fetchTheme(organization: Organization, link?: NavigationLink): Promise<ThemableComponents> {
    return apiService.get(ThemableComponents, link ? link.href : `/organizations/${organization.id}/theme`);
  }

  public static fetchThemeForPlatform(organization: Organization, platform: string, link?: NavigationLink): Promise<ThemableComponents> {
    return apiService.get(ThemableComponents, link ? link.href : `/organizations/${organization.id}/theme/platform/${platform}`);
  }

  public static fetchThemeCssForPlatform(organization: Organization, platform: string, link?: NavigationLink): Promise<StringValue> {
    return apiService.get(StringValue, link ? link.href : `/organizations/${organization.id}/theme/platform/${platform}/css`);
  }

  public static postThemeComponent(organization: Organization, component: ThemableComponent): Promise<ThemableComponent> {
    return apiService.post(ThemableComponent, NavigationLinks.getLinkValueFromNavigation("theme",
        `/organizations/${organization.id}/theme`, organization.nav), component);
  }

  public static listLogos(organization: Organization): Promise<ItemContentEntityContainer> {
    return new Promise<ItemContentEntityContainer>((resolve, reject) => {
      if (organization.logos && organization.logos.list && organization.logos.list.length > 0) {
        resolve(organization.logos);
      } else {
        if (organization.nav) {
          let link = organization.nav.getLink("logos");
          if (link) {
            return apiService.get(ItemContentEntityContainer, link.href);
          }
        }
        return apiService.get(ItemContentEntityContainer, `/organizations/${organization.id}/logos`); // Fallback
      }
    });
  }

  public static fetchItemContentEntity(holderEntity: Entity, itemContentEntity: ItemContentEntity): Promise<Blob> {
    let link: NavigationLink | undefined =
        NavigationLinks.getLinkFromLinks(NavigationLinkType.PAGE_SELF, itemContentEntity.links);
    if (link) {
      return apiService.img(link.href);
    }
    return new Promise<Blob>((resolve, reject) => {
      reject(new Error("No item content entity defined"));
    });
  }

  public static postItemContentEntity(holderEntity: Entity, itemContentEntity: ItemContentEntity, file: File): Promise<ItemContentEntity> {
    const formData = new FormData();
    formData.append('content', file, file.name);

    let link: NavigationLink | undefined = NavigationLinks.getLinkFromLinks(NavigationLinkType.PRIVATE, itemContentEntity.links);
    if (link) {
      return apiService.multipart(ItemContentEntity, link.href, formData);
    }

    return apiService.multipart(ItemContentEntity, `/contents/${holderEntity.id}/${itemContentEntity.type}`, formData);
  }

  public static deleteItemContentEntity(holderEntity: Entity, itemContentEntity: ItemContentEntity): Promise<any> {
    let link: NavigationLink | undefined =
        NavigationLinks.getLinkFromLinks(NavigationLinkType.PRIVATE, itemContentEntity.links);
    if (link) {
      return apiService.delete(Any, link.href);
    }
    return apiService.delete(Any, `/contents/${holderEntity.id}/${itemContentEntity.type}`);
  }

  public static fetchOrganizationBilling(organization: Organization, link?: NavigationLink): Promise<OrganizationBilling> {
    return apiService.get(OrganizationBilling, link ? link.href : `/organizations/${organization.id}/billing`);
  }

  public static updateOrganizationBilling(organization: Organization, billing: OrganizationBilling, link?: NavigationLink): Promise<OrganizationBilling> {
    return apiService.put(OrganizationBilling, link ? link.href : `/organizations/${organization.id}/billing`, billing);
  }

  public static fetchOrganizationModules(organization: Organization, link?: NavigationLink): Promise<OrganizationModuleContainer> {
    return apiService.get(OrganizationModuleContainer, link ? link.href : `/organizations/${organization.id}/modules`);
  }

  public static postOrganizationModule(organization: Organization, module: OrganizationModule): Promise<OrganizationModule> {
    return apiService.post(OrganizationModule, NavigationLinks.getLinkValueFromNavigation("modules",
      `/organizations/${organization.id}/modules`, organization.nav), module);
  }

  public static updateOrganizationModuleFeature(organization: Organization, moduleType: string,
                                                feature: OrganizationModuleFeature): Promise<OrganizationModuleFeature> {
    return apiService.put(OrganizationModuleFeature, NavigationLinks.getLinkValueFromNavigation("modules",
      `/organizations/${organization.id}/modules/${moduleType}/feature`, organization.nav), feature);
  }

  public static fetchUserRegistryClients(organization: Organization): Promise<UserRegistryClients> {
    return apiService.get(UserRegistryClients, `/organizations/${organization.id}/user-registry/clients`);
  }

  public static createUserRegistryClient(organization: Organization, registration: string): Promise<UserRegistryClient> {
    return apiService.post(UserRegistryClient, `/organizations/${organization.id}/user-registry/clients/registrations/${registration}`);
  }

  public static fetchShopRegistryClients(organization: Organization): Promise<ShopRegistryClients> {
    return apiService.get(ShopRegistryClients, `/organizations/${organization.id}/shop-registry/clients`);
  }

  public static syncShopRegistryClient(organization: Organization, client: EntityRegistryClient, internal: boolean): Promise<EntityRegistryDataSourceEvent> {
    return apiService.post(EntityRegistryDataSourceEvent, `/organizations/${organization.id}/shop-registry/clients/${client.id}/sync?internal=${internal}`);
  }

  public static createShopRegistryClient(organization: Organization, provider: string): Promise<ShopRegistryClient> {
    return apiService.post(ShopRegistryClient, `/organizations/${organization.id}/shop-registry/clients/providers/${provider}`);
  }

  public static updateShopRegistryClientForProvider(organization: Organization, client: ShopRegistryClient): Promise<ShopRegistryClient> {
    return apiService.put(ShopRegistryClient, `/organizations/${organization.id}/shop-registry/clients/${client.id}/providers/${client.provider.name}`, client.provider.data);
  }

  public static getEntityRegistryClientEvents(organization: Organization, client: EntityRegistryClient): Promise<EntityContainer<unknown>> {
    return apiService.get(EntityContainer, `/organizations/${organization.id}/entity-registry/clients/${client.id}/events`);
  }

  public static updateEntityRegistryClient(organization: Organization, client: EntityRegistryClient): Promise<EntityRegistryClient> {
    return apiService.put(EntityRegistryClient, `/organizations/${organization.id}/entity-registry/clients/${client.id}`, client);
  }

  public static createEntityRegistryClientFilter(organization: Organization, clientId: number, filter: EntityRegistryClientFilter): Promise<EntityRegistryClientFilter> {
    return apiService.post(EntityRegistryClientFilter, `/organizations/${organization.id}/entity-registry/clients/${clientId}/filters`, filter);
  }

  public static updateEntityRegistryClientFilter(organization: Organization, clientId: number, filter: EntityRegistryClientFilter): Promise<EntityRegistryClientFilter> {
    return apiService.put(EntityRegistryClientFilter, `/organizations/${organization.id}/entity-registry/clients/${clientId}/filters`, filter);
  }

  public static deleteEntityRegistryClientFilter(organization: Organization, clientId: number, id: number): Promise<Any> {
    return apiService.delete(Any, `/organizations/${organization.id}/entity-registry/clients/${clientId}/filters/${id}`);
  }

  public static createEntityRegistryClientMapping(organization: Organization, clientId: number, filter: EntityRegistryClientMapping): Promise<EntityRegistryClientMapping> {
    return apiService.post(EntityRegistryClientMapping, `/organizations/${organization.id}/entity-registry/clients/${clientId}/mappings`, filter);
  }

  public static updateEntityRegistryClientMapping(organization: Organization, clientId: number, mapping: EntityRegistryClientMapping): Promise<EntityRegistryClientMapping> {
    return apiService.put(EntityRegistryClientMapping, `/organizations/${organization.id}/entity-registry/clients/${clientId}/mappings`, mapping);
  }

  public static deleteEntityRegistryClientMapping(organization: Organization, clientId: number, id: number): Promise<Any> {
    return apiService.delete(Any, `/organizations/${organization.id}/entity-registry/clients/${clientId}/mappings/${id}`);
  }

  public static fetchStatus(link?: NavigationLink): Promise<StatusUpdates> {
    return apiService.get(StatusUpdates, link ? link.href : '/status/updates');
  }

  public static fetchLastFeaturedSubmission(): Promise<FeaturedSubmission> {
    return apiService.get(FeaturedSubmission, '/items/last/featured');
  }

  public static fetchFeed(link?: NavigationLink): Promise<Feed> {
    return apiService.get(Feed, link ? link.href : '/feed/articles');
  }

  public static fetchSubmissionList(link?: NavigationLink): Promise<SubmissionContainer> {
    return apiService.get(SubmissionContainer, link ? link.href : '/items/list');
  }

  // Simple filter
  public static fetchSubmissionListWithCriteria(criteria: SubmissionQueryCriteria, link?: NavigationLink): Promise<SubmissionContainer> {
    return apiService.get(SubmissionContainer, Optional(link).href('/items/list'), criteria);
  }

  // Advanced filter
  public static fetchSubmissionListWithFilter(filter: string, link?: NavigationLink): Promise<SearchCriteria> {
    return apiService.get(SearchCriteria, Optional(link).href(`/items/filter${filter}`));
  }

  public static searchSubmissions(searchTerm: string, link?: NavigationLink): Promise<SearchResult> {
    return apiService.get(SearchResult, link ? link.href : '/items/search', {
      q: link ? null : searchTerm
    });
  }

  public static fetchShareContents(): Promise<ShareItem> {
    return apiService.get(ShareItem, `/items/share`);
  }

  public static postShareCurrentView(criteria: SearchCriteriaSelectedDataValue[]): Promise<ShareItem> {
    return apiService.post(ShareItem, `/items/filter/share`, criteria);
  }

  public static postShareItem(id: number, link?: NavigationLink): Promise<ShareItem> {
    return apiService.post(ShareItem, link ? link.href : `/items/${id}/share`);
  }

  public static postShareItems(ids: Number[]): Promise<ShareItem> {
    return apiService.post(ShareItem, `/items/share`, ids);
  }

  public static fetchGallery(link?: NavigationLink): Promise<Gallery> {
    return apiService.get(Gallery, link ? link.href : '/items/gallery');
  }

  public static fetchSubmissionDetails(itemId: number, link?: NavigationLink): Promise<Item> {
    return apiService.get(Item, link ? link.href : `/items/${itemId}/details`);
  }

  public static fetchLikes(submission: Item): Promise<ItemLikes> {
    return apiService.get(ItemLikes, `/items/${submission.id}/likes`);
  }

  public static postLike(submission: Item): Promise<ItemLike> {
    return apiService.post(ItemLike, `/items/${submission.id}/likes`);
  }

  public static fetchComments(submission: Item): Promise<ItemComments> {
    return apiService.get(ItemComments, `/items/${submission.id}/comments`);
  }

  public static postComment(submission: Item, comment: ItemComment): Promise<ItemComment> {
    return apiService.post(ItemComment, `/items/${submission.id}/comments`, comment);
  }

  public static fetchImage(link: NavigationLink): Promise<Blob> {
    return apiService.img(link.href);
  }

  public static fetchUploadConfiguration(link?: NavigationLink): Promise<UploadConfiguration> {
    return apiService.get(UploadConfiguration, link ? link.href : '/upload/configuration');
  }

  public static fetchEntityContainer(link: NavigationLink): Promise<EntityContainer<any>> {
    return apiService.get(EntityContainer, link.href);
  }

  public static fetchClosestShop(location: GeoLocation): Promise<Shop> {
    return apiService.get(Shop, '/shops/closest', {
      lat: location.latitude,
      lng: location.longitude
    });
  }

  public static fetchClosestShops(location: GeoLocation, referenceLocation?: GeoLocation, limit?: Number): Promise<ShopContainer> {
    return apiService.get(ShopContainer, '/shops', {
      lat: location.latitude,
      lng: location.longitude,
      lat_ref: referenceLocation ? referenceLocation.latitude : null,
      lng_ref: referenceLocation ? referenceLocation.longitude : null,
      size: limit
    });
  }

  public static searchForShops(text: String, location?: GeoLocation): Promise<ShopContainer> {
    return apiService.get(ShopContainer, '/shops/search', {
      q: text,
      lat: location ? location.latitude : null,
      lng: location ? location.longitude : null
    });
  }

  public static fetchUserRelevantShops(link?: NavigationLink): Promise<ShopContainer> {
    return apiService.get(ShopContainer, link ? link.href : '/shops/relevant');
  }

  public static fetchShopDetails(shop: Shop, link?: NavigationLink): Promise<ShopDetailsWithMetadata> {
    return new Promise<ShopDetailsWithMetadata>((resolve, reject) => {
      apiService.get(ShopDetailsWithMetadata, link ? link.href : `/shops/${shop.id}/registry/details`).then((shopDetails: ShopDetailsWithMetadata) => {
        // The API does not return the distance from current location to the shop, we propagate it if we have it...
        if (!shopDetails.distance && shop.distance) {
          shopDetails.distance = shop.distance;
        }
        resolve(shopDetails);
      }).catch((error: BackendError) => {
        reject(error);
      });
    });
  }

  public static fetchProjectsForShop(shop: Shop, link?: NavigationLink): Promise<ProjectContainer> {
    return apiService.get(ProjectContainer, link ? link.href : `/shops/${shop.id}/projects`);
  }

  public static fetchProjectDetails(project: Project, shop: Shop, link?: NavigationLink): Promise<ProjectDetails> {
    if (shop) {
      return apiService.get(ProjectDetails, link ? link.href : `/projects/${project.id}/details?shid=${shop.id}`);
    }
    return apiService.get(ProjectDetails, link ? link.href : `/projects/${project.id}/details`);
  }

  public static fetchProjectDocumentationAttachmentAccessLink(nav: NavigationLinks): Promise<NavigationLinks> {
    let link = nav.getLink("access");
    if (link) {
      return apiService.get(NavigationLinks, link.href);
    } else {
      throw new BackendError(ApiError.UNKNOWN, "Missing link to access project documentation attachment");
    }
  }

  public static fetchProjectsWithProgress(link: NavigationLink): Promise<ProjectProgressContainer> {
    return apiService.get(ProjectProgressContainer, link ? link.href : `/projects/progress`);
  }

  public static fetchProjectProgress(projectId: number, link?: NavigationLink): Promise<ProjectProgress> {
    return apiService.get(ProjectProgress, link ? link.href : `/projects/${projectId}/progress`);
  }

  public static fetchProjectDepartmentsStandings(projectId: number, link?: NavigationLink): Promise<ProjectProgressForDepartmentContainer> {
    return apiService.get(ProjectProgressForDepartmentContainer, link ? link.href : `/projects/${projectId}/departments`);
  }

  public static fetchProjectUsersStandings(projectId: number, link?: NavigationLink): Promise<ProjectProgressForUserContainer> {
    return apiService.get(ProjectProgressForUserContainer, link ? link.href : `/projects/${projectId}/users`);
  }

  public static searchForProjectsWithProgress(text: String): Promise<ProjectProgressContainer> {
    return apiService.get(ProjectProgressContainer, '/projects/search', {
      q: text
    });
  }

  public static fetchCategories(link?: NavigationLink, categoryType?: CategorySetType, categorySetEntityId?: number): Promise<CategoryContainer> {
    let baseUrl: string = '/categories/flatten';
    if (categorySetEntityId) {
      if (categoryType === CategorySetType.PROJECT) {
        baseUrl += `?pid=${categorySetEntityId}`;
      } else if (categoryType === CategorySetType.SHOP) {
        baseUrl += `?shid=${categorySetEntityId}`;
      }
    }
    return apiService.get(CategoryContainer, link ? link.href : baseUrl);
  }

  public static postSubmissionEntities(path: string, submissionEntities: SubmissionUploadData): Promise<Item> {
    return apiService.post(Item, path, submissionEntities);
  }

  public static getUpdateUserSettingsMetadata(): Promise<UpdateUserSettingsMetadata> {
    return apiService.get(UpdateUserSettingsMetadata, '/users/authenticated/settings/metadata');
  }

  public static putAuthenticatedUserSettings(userSettings: UserSettings): Promise<UserSettings> {
    return apiService.put(UserSettings, '/users/authenticated/settings', userSettings);
  }

  // Filter
  public static fetchSearchCriteria(navLink: NavigationLink | undefined, filters: string): Promise<SearchCriteria> {
    return apiService.get(SearchCriteria, `${Optional(navLink).href('/items/filter')}${filters}`);
  }

  public static fetchRemoteSearchCriteria(navLink: NavigationLink, searchPhrase: string): Promise<SearchCriterion> {
    if (navLink) {
      return apiService.get(SearchCriterion, navLink.href, {
        q: searchPhrase
      });
    }

    return Promise.reject(new Error('No link to fetch remote-search!'));
  }

  // Map related
  public static fetchMapSubmissions(navLink?: NavigationLink, filters?: string): Promise<MapSubmissionContainer> {
    return apiService.get(MapSubmissionContainer, Optional(navLink).href(`/items/map${filters}`));
  }

  public static fetchMapShopDetails(navLink?: NavigationLink, type?: any): Promise<any> {
    return apiService.get(type, Optional(navLink).href(''));
  }

  public static fetchMapShopsByChain(navLink?: NavigationLink, chid?: number): Promise<MapShopContainer> {
    return apiService.get(MapShopContainer, Optional(navLink).href(`/chains/${chid}/shops`));
  }

  public static fetchMapProject(navLink?: NavigationLink, pid?: number,
                                projectStatuses?: string): Promise<MapSubmissionContainer> {
    return apiService.get(MapSubmissionContainer,
      Optional(navLink).href(`/projects/${pid}/statuses/map?${projectStatuses}&size=${config.itemsPerMapAjaxCall}`));
  }

  public static fetchAuditLogEvents(organization: Organization, entityType?: string, entityId?: number): Promise<EntityContainer<AuditLogEvent>> {
    if (entityType === null || entityId === null) {
      return apiService.get(EntityContainer, `/organizations/${organization.id}/audit`);
    }

    return apiService.get(EntityContainer, `/organizations/${organization.id}/type/${entityType}/entity/${entityId}/audit`);
  }
}
