
  import Vue from 'vue';
  import { mapState } from "vuex";
  import { Map, Marker } from 'leaflet';
  import { oc as Optional } from "ts-optchain";
  import { LControl } from "vue2-leaflet";
  import messages from '@/main/webapp/vue/components/map/messages.json';

  import baseMap from '@/main/webapp/vue/components/map/base-map/index.vue';
  import PruneCluster from '@/main/webapp/vue/components/map/prune-cluster/index.vue';
  import markers from '@/main/webapp/vue/components/map/marker/index.vue';
  import loadingIcon from '@/main/webapp/vue/components/ui/loading-icon/index.vue';
  import mapLegend from '@/main/webapp/vue/components/map/legend/index.vue';
  import SubmissionPopup from '@/main/webapp/vue/components/map/marker/marker-popup/submission-popup/index.vue';
  import notification from "@/main/webapp/vue/notification";

  import mapConfiguration from '@/main/webapp/vue/components/map/mapConfiguration';
  import markerConfig from "@/main/webapp/vue/components/map/markerConfig";
  import mapHtmlConfiguration from "@/main/webapp/vue/components/map/mapHtmlConfiguration";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";

  import { MapSubmissionContainer } from '@/main/webapp/vue/model/api/Map/MapSubmissionContainer';
  import { MapSubmission } from "@/main/webapp/vue/model/api/Map/MapSubmission";
  import { MapSubmissionDetails } from "@/main/webapp/vue/model/api/Map/MapSubmissionDetails";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { MapMarkerContainer } from "@/main/webapp/vue/model/api/Map/MapMarkerContainer";
  import { MapMarker } from "@/main/webapp/vue/model/api/Map/MapMarker";
  import { MapCategory } from "@/main/webapp/vue/model/api/Map/MapCategory";
  import { IconTypes } from "@/main/webapp/vue/model/api/Map/IconTypes";
  import { FilterAttributes } from "@/main/webapp/vue/views/submissions-view/ui/submissions-switcher/index.vue";

  export default Vue.extend({
    components: {
      baseMap,
      PruneCluster,
      markers,
      loadingIcon,
      LControl,
      mapLegend
    },
    data() {
      return {
        locations: [] as MapMarker[],
        nextPage: undefined as NavigationLink | undefined,
        mapObject: undefined as Map | undefined,
        mapHeight: `${window.innerHeight * 0.7}px` as string, // thumbs map page - map size
        markerColors: mapConfiguration.config.markerColors as string[],
        viewInClusters: true as boolean,
        iconTypes: mapConfiguration.config.iconTypes as IconTypes,
        loading: false as boolean,
        legends: [] as MapCategory[],
        currentFilters: '' as string
      };
    },
    computed: {
      ...mapState([
        'lastFilterAttributes'
      ])
    },
    watch: {
      lastFilterAttributes: {
        handler(newFilter: FilterAttributes, oldFilter: FilterAttributes): void {
          if (this.currentFilters !== newFilter.parameters) {
            this.initMap();
          }
        },
        deep: true
      }
    },
    methods: {
      viewWithoutCluster(): void {
        this.viewInClusters = false;
      },
      fitBounds(): void {
        if (this.mapObject) {
          mapConfiguration.fitBounds(this.mapObject, this.locations, 100);
        }
      },
      addMarkers(transformedMarkers: MapMarkerContainer, fitBounds: Boolean): void {
        if (transformedMarkers.markers) {
          this.locations.push.apply(this.locations, transformedMarkers.markers);
        }

        if (transformedMarkers.markerColors) {
          this.markerColors = transformedMarkers.markerColors;
        }

        if (window.location.search.indexOf('last') > 0) {
          this.viewWithoutCluster();
        }

        if (fitBounds) {
          this.fitBounds();
          // this.getMapLegends();
        }
      },
      markerClicked(item: MapSubmission, leafletMarker: Marker): void {
        if (!this.viewInClusters && this.mapObject) { // If not viewInCluster, manually zoom
          this.mapObject.setView(leafletMarker.getLatLng(), mapConfiguration.config.defaultCloseZoom);
        }

        if (leafletMarker.getPopup() === undefined) {
          let nav: NavigationLinks | undefined = item.nav;
          if (nav) {
            let nextLink: NavigationLink | undefined = nav.getLink(NavigationLinkType.PAGE_SELF);
            if (nextLink) {
              BackendIntegrationService.fetchMapShopDetails(nextLink, MapSubmissionDetails).then((response: MapSubmissionDetails) => {
                // Trick to use vue component html
                const submissionPopupElement: string = new SubmissionPopup({
                  propsData: {
                    item: response
                  }
                }).$mount().$el.outerHTML;

                leafletMarker.bindPopup(submissionPopupElement, { minWidth: 200 });
                leafletMarker.openPopup();
              }).catch((e: Error) => {
                if (process.env.NODE_ENV !== 'production') {
                  console.log("Get details failed:", e);
                }
                notification.fail(this.$t('text.error.detail'));
              });
            }
          }
        }
      },
      getMapLegends(): void {
        setTimeout(() => {
          this.legends = mapHtmlConfiguration.getCategoriesFromFilter();
        }, 1500);
      },
      updateSubmissionMap(fetchingFilters: string): void {
        this.loading = true;

        BackendIntegrationService.fetchMapSubmissions(this.nextPage, fetchingFilters)
          .then((response: MapSubmissionContainer) => {
            if (this.currentFilters !== fetchingFilters) {
              if (process.env.NODE_ENV !== 'production') {
                console.log('Abort previous locations since the url changed!');
              }
              return;
            }

            if (response.list && response.list.length > 0) {
              let transformedMarkers: MapMarkerContainer = markerConfig.transformToMarkers(response.list, this.markerColors);
              if (transformedMarkers) {
                this.addMarkers(transformedMarkers, this.nextPage === undefined);
              }

              let nav: NavigationLinks | undefined = Optional(response).nav();
              if (nav) {
                let nextLink: NavigationLink | undefined = nav.getLink(NavigationLinkType.PAGE_NEXT);
                if (nextLink) {
                  this.nextPage = nextLink;
                  this.updateSubmissionMap(fetchingFilters);
                } else {
                  this.loadNoMore();
                }
              } else {
                this.loadNoMore();
              }
            } else {
              this.loadNoMore();
            }
          }).catch((error: Error) => {
            if (process.env.NODE_ENV !== 'production') {
              console.log('Get submission locations failed', error);
            }
            notification.fail(this.$t('text.error.location'));
            this.loadNoMore();
          });
      },
      loadNoMore(): void {
        this.nextPage = undefined;
        this.loading = false;
      },
      clearMap(): void {
        this.locations = [];
        this.loadNoMore();
      },
      initMap(map: Map | null = null): void {
        this.clearMap();
        this.currentFilters = this.lastFilterAttributes.parameters;

        if (map) { // Only when init
          this.mapObject = map;
        }

        this.updateSubmissionMap(this.currentFilters);
      }
    },
    i18n: {
      messages: messages
    }
  });
