import { WebSocketResponseType } from "./WebSocketResponseType";
import { WebSocketTaskType } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskType";
import { WebSocketPayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketPayload";

export class WebSocketResponse {
  webSocketResponseType: WebSocketResponseType | undefined = undefined;
  webSocketTaskType: WebSocketTaskType | undefined = undefined;
  executionsDestination: string | undefined = undefined;
  instructionsDestination: string | undefined = undefined;
  subscriptionsDestination: string | undefined = undefined;
  hostName: string | undefined = undefined;
  payload: WebSocketPayload | undefined = undefined;
  headers: { [key: string]: string } | undefined = undefined;
}
