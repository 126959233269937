import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import VueI18n from "vue-i18n";

import configuration from './config/application/configuration';
import paths from './urlPath';
import moment from 'moment-timezone';

import { User } from "@/main/webapp/vue/model/api/User";
import { MapProperties } from "@/main/webapp/vue/model/api/Map/MapProperties";
import { CourseBrandingStyle } from "@/main/webapp/vue/model/api/learning/CourseBrandingStyle";
import { CourseBrandingStyles } from "@/main/webapp/vue/model/learning/CourseBrandingStyles";
import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
import { AuthenticationResult } from "@/main/webapp/vue/model/api/AuthenticationResult";
import { FilterAttributes } from "@/main/webapp/vue/views/submissions-view/ui/submissions-switcher/index.vue";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  key: 'vuex',
  storage: window.localStorage
});

export default new Vuex.Store({
  state: {
    i18n: {
      locale: 'en',
      messages: {}
    },
    userDetails: User,
    backendLinks: new NavigationLinks() as NavigationLinks,
    collapsedTypes: paths,
    hostName: '',
    searchString: '',
    switchOrganizationSearchString: '',
    webSocketTaskStatuses: {},
    mapProperties: {} as MapProperties,
    courseDefaultStyles: new CourseBrandingStyles().default() as CourseBrandingStyle[],
    lastFilterAttributes: new FilterAttributes(null, '') as FilterAttributes,
    clearSelectedSubmissions: false as boolean,
    removeSelectedSubmission: null as null | number
  },
  actions: {
    updateAuthResult(context: any, result: AuthenticationResult): void {
      context.commit("updateUserDetails", result.user);
      context.commit("updateBackendLinks", result.nav);
      context.commit("updateFilterAttributes", new FilterAttributes(null, ''));
    },
    updateUserDetails(context: any, user: User): void {
      context.commit('updateUserDetails', user);
    },
    updateBackendLinks(context: any, links: NavigationLinks): void {
      context.commit('updateBackendLinks', links);
    },
    setLocale(context: any, i18n: VueI18n): void {
      context.commit('updateLocale', i18n);
    },
    updateCourseDefaultStyles(context: any, newStyles: CourseBrandingStyle[]): void {
      this.state.courseDefaultStyles = new CourseBrandingStyles().default();
      if (newStyles) {
        this.state.courseDefaultStyles.forEach((defaultStyle: CourseBrandingStyle, index: number) => {
          let attachedStyle: CourseBrandingStyle = newStyles.filter((newStyle: CourseBrandingStyle) => newStyle.type === defaultStyle.type)[0];
          if (attachedStyle && attachedStyle.data) {
            this.state.courseDefaultStyles[index] = attachedStyle;
          }
        });
      }
    },
    updateFilterAttributes(context: any, filterAttributes: FilterAttributes): void {
      context.commit('updateFilterAttributes', filterAttributes);
    },
    updateClearSubmissions(context: any): void {
      this.state.clearSelectedSubmissions = !this.state.clearSelectedSubmissions; // Don't save it locally since share content has session
    },
    updateRemoveSelectedSubmission(context: any, id: number): void {
      this.state.removeSelectedSubmission = id; // Don't save it locally since share content has session
    }
  },
  mutations: {
    updateLocale(state: any, i18n: VueI18n): void {
      state.i18n.locale = i18n.locale;
      state.i18n.messages = i18n.messages;

      const availableLocales: any = configuration.properties.availableLocales;
      moment.locale(availableLocales[state.i18n.locale]);
    },
    updateUserDetails(state: any, userDetails: User): void {
      state.userDetails = userDetails;
      state.clearSelectedSubmissions = !state.clearSelectedSubmissions;
    },
    updateBackendLinks(state: any, links: NavigationLinks): void {
      state.backendLinks = links;
    },
    updateTranslated(state: any, translated: boolean): void {
      state.translated = translated;
    },
    updateCollapsibleTypes(state: any, params): void {
      state.collapsedTypes.paths[params.urlPath] = params.collapsibleType;
    },
    updateTaskStatus(state: any, payload): void {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Vue Store - updateWebSocketTaskStatus()');
        console.log(payload);
      }

      state.webSocketTaskStatuses[payload.key] = payload.value;
    },
    updateHostName(state: any, hostName: string): void {
      state.hostName = hostName;
    },
    updateSearchString(state: any, searchString: string): void {
      state.searchString = searchString;
    },
    updateSwitchOrganizationSearchString(state: any, switchOrganizationSearchString: string): void {
      state.switchOrganizationSearchString = switchOrganizationSearchString;
    },
    updateCourseDefaultStyle(state: any, newStyle: CourseBrandingStyle): void {
      state.courseDefaultStyles.forEach((defaultStyle: CourseBrandingStyle) => {
        if (defaultStyle.type === newStyle.type && newStyle.data) {
          defaultStyle = newStyle;
        }
      });
    },
    updateFilterAttributes(state: any, filterAttributes: FilterAttributes): void {
      if (filterAttributes.view !== null) {
        state.lastFilterAttributes.view = filterAttributes.view;
      }

      state.lastFilterAttributes.parameters = filterAttributes.parameters;
    }
  },
  getters: {
    getLocale: (state: any) => state.i18n,
    getUserDetails: (state: any) => state.userDetails
  },
  plugins: [vuexLocal.plugin]
});
