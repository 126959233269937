import Vue from 'vue';
import Router from 'vue-router';

import coursePage from "@/main/webapp/vue/components/learning/learning-home/index.vue";
import courseList from "@/main/webapp/vue/components/learning/learning-home/course-list/index.vue";
import courseManagement from "@/main/webapp/vue/components/learning/learning-home/course-management/index.vue";
import courseSettings from "@/main/webapp/vue/components/learning/learning-home/course-management/course-settings/index.vue";
import courseActivities from "@/main/webapp/vue/components/learning/learning-home/course-management/course-activities/index.vue";
import courseActivityPreview from "@/main/webapp/vue/components/learning/learning-home/course-management/course-activities/activity-preview/index.vue";
import courseBranding from "@/main/webapp/vue/components/learning/learning-home/course-management/course-branding/index.vue";

import feedView from "@/main/webapp/vue/views/submissions-view/feed-view/index.vue";
import thumbnailView from "@/main/webapp/vue/views/submissions-view/thumbnail-view/index.vue";
import submissionsListView from "@/main/webapp/vue/views/submissions-view/list-view/index.vue";
import submissionsMap from "@/main/webapp/vue/components/map/submissions-map/index.vue";
import submissionsDashboard from "@/main/webapp/vue/views/submissions-view/dashboard-view/index.vue";

import { RouteName } from "@/main/webapp/vue/model/RouteName";
import { RoutePath } from "@/main/webapp/vue/model/RoutePath";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    { path: RoutePath.LEARNING,
      component: coursePage,
      children: [
        // course-list displayed by default
        { path: '', component: courseList },
        { name: RouteName.COURSE_MANAGEMENT,
          path: RoutePath.COURSE_MANAGEMENT,
          component: courseManagement,
          props: true,
          children: [
            { path: RoutePath.COURSE_SETTINGS, component: courseSettings },
            { path: RoutePath.COURSE_ACTIVITIES, component: courseActivities },
            { path: RoutePath.COURSE_ACTIVITY_PREVIEW, component: courseActivityPreview },
            { path: RoutePath.COURSE_BRANDING, component: courseBranding }
          ]
        }
      ]
    },
    { path: RoutePath.SUBMISSIONS_FEED, component: feedView },
    { path: RoutePath.SUBMISSIONS_THUMB, component: thumbnailView },
    { path: RoutePath.SUBMISSIONS_LIST, component: submissionsListView },
    { path: RoutePath.SUBMISSIONS_MAP, component: submissionsMap },
    { path: RoutePath.SUBMISSIONS_DASHBOARD, component: submissionsDashboard },
    { path: '/*' }
  ]
});
