
  import Vue from 'vue';

  import navigationSection from '@/main/webapp/vue/components/ui/navigation/section/index.vue';
  import navigationSectionElement from '@/main/webapp/vue/components/ui/navigation/section/element/index.vue';

  import shopList from '@/main/webapp/vue/components/admin/shop-configurator/shop-list/index.vue';
  import chainList from '@/main/webapp/vue/components/admin/shop-configurator/chain-list/index.vue';
  import dataSources from '@/main/webapp/vue/components/admin/shop-configurator/data-sources/index.vue';
  import chainShopImport from '@/main/webapp/vue/components/admin/shop-configurator/chain-shop-import/index.vue';

  export default Vue.extend({
    components: {
      navigationSection,
      navigationSectionElement,
      shopList,
      chainList,
      dataSources,
      chainShopImport
    }
  });
