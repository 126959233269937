
  import Vue, { PropType } from 'vue';

  import webSocketRequest from "@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/index.vue";

  import { BinaryWebSocketTaskPayload } from "@/main/webapp/vue/model/api/web-socket/BinaryWebSocketTaskPayload";

  export default Vue.extend({
    components: {
      webSocketRequest
    },
    props: {
      type: {
        type: String, // Object as PropType<WebSocketTaskType>,
        required: true
      },
      visible: {
        type: Boolean,
        default: true
      },
      shopImportFile: {
        type: File,
        default: null
      },
      shopImportKeepExistingData: {
        type: Boolean,
        default: true
      },
      shopImportReplaceResponsibles: {
        type: Boolean,
        default: false
      },
      shopImportInactivateShops: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        payload: new BinaryWebSocketTaskPayload() as BinaryWebSocketTaskPayload
      };
    },
    watch: {
      shopImportFile(newValue: File, oldValue: File): void {
        if (newValue) {
          newValue.arrayBuffer().then((arrayBuffer: ArrayBuffer) => {
            this.payload.binary = true;
            this.payload.file = new Uint8Array(arrayBuffer);
            this.payload.addExtraData("keep-existing-data", String(this.shopImportKeepExistingData));
            this.payload.addExtraData("replace-responsibles", String(this.shopImportReplaceResponsibles));
            this.payload.addExtraData("inactivate-shops", String(this.shopImportInactivateShops));
          });
        }
      },
      shopImportKeepExistingData(newValue: boolean, oldValue: boolean): void {
        this.payload.addExtraData("keep-existing-data", String(this.shopImportKeepExistingData));
      },
      shopImportReplaceResponsibles(newValue: boolean, oldValue: boolean): void {
        this.payload.addExtraData("replace-responsibles", String(this.shopImportReplaceResponsibles));
      },
      shopImportInactivateShops(newValue: boolean, oldValue: boolean): void {
        this.payload.addExtraData("inactivate-shops", String(this.shopImportInactivateShops));
      }
    }
  });
