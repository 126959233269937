import 'core-js/stable';

import Vue from 'vue';
import { Route } from "vue-router";
import VueHead from 'vue-head';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import { VueEditor } from 'vue2-editor';
// We use Bootstrap directly imported to the JSPs, instead of this: import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import VueLazyload from 'vue-lazyload';
import VueI18n from 'vue-i18n';
import moment from 'moment-timezone';
import Notifications from 'vue-notification';
import { Icon } from 'leaflet';
import VCalendar from 'v-calendar';
import { registerUnusedTypes } from '@znapio/znapson';

import router from './router';
import store from './store';
import applicationConfiguration from './config/application/configuration';
import mapConfiguration from "@/main/webapp/vue/components/map/mapConfiguration";
import internationalization from './config/internationalization/internationalization';

import Console from '@/main/webapp/vue/components/ui/console/index.vue';
import Settings from '@/main/webapp/vue/components/settings/index.vue';
import ItemMapContainer from '@/main/webapp/vue/components/map/item-map/index.vue';
import ShopsMapContainer from '@/main/webapp/vue/components/map/shops-map/index.vue';
import ShopsModalMapContainer from '@/main/webapp/vue/components/map/shops-modal-map/index.vue';
import ProjectMapContainer from '@/main/webapp/vue/components/map/project-map/index.vue';
import ProjectModalMapContainer from '@/main/webapp/vue/components/map/project-modal-map/index.vue';
import UploadMapContainer from '@/main/webapp/vue/components/map/upload-map/index.vue';
import OrganizationConfigurator from "@/main/webapp/vue/components/admin/organization-configurator/index.vue";
import ThemeConfigurator from '@/main/webapp/vue/components/admin/theme-configurator/index.vue';
import LogoConfigurator from '@/main/webapp/vue/components/admin/logo-configurator/index.vue';
import ModuleConfigurator from '@/main/webapp/vue/components/admin/module-configurator/index.vue';
import ShopConfigurator from '@/main/webapp/vue/components/admin/shop-configurator/index.vue';
import UserConfigurator from '@/main/webapp/vue/components/admin/user-configurator/index.vue';
import UserLoader from "@/main/webapp/vue/components/ui/user/loader/index.vue";
import ProjectProgress from "@/main/webapp/vue/components/project/progress/index.vue";
import ProjectStandings from "@/main/webapp/vue/components/project/standings/index.vue";
import AssumeUser from '@/main/webapp/vue/components/admin/assume-user/assume/index.vue';
import UnassumeUser from '@/main/webapp/vue/components/admin/assume-user/unassume/index.vue';
import SubmissionMetadata from '@/main/webapp/vue/components/ui/submission/submission-metadata/index.vue';
import QuickSearch from '@/main/webapp/vue/components/ui/header/quick-search/index.vue';
import LearningHome from '@/main/webapp/vue/components/learning/learning-home/index.vue';
import LearningOnline from '@/main/webapp/vue/components/learning/learning-online/index.vue';
import EntityButton from '@/main/webapp/vue/components/ui/entity-button/index.vue';
import WorkspaceSwitcher from '@/main/webapp/vue/components/ui/drop-down/workspace-switcher/index.vue';
import ResponsibleShopsPopover from '@/main/webapp/vue/components/ui/bootstrap-popover/responsible-shops-popover/index.vue';
import Filter from '@/main/webapp/vue/components/ui/filter/index.vue';
import WebSocketRequest from '@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/index.vue';
import WebSocketFilterRequest from '@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/web-socket-filter-request/index.vue';
import WebSocketImportRequest from '@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/web-socket-import-request/index.vue';
import ProjectReportRequest from '@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/project-report-request/index.vue';
import ReportingDropdown from '@/main/webapp/vue/components/web-socket/reporting/reporting-dropdown/index.vue';
import WebSocketStatusChecker from '@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-status-checker/index.vue';
import WebSocketStatus from '@/main/webapp/vue/components/web-socket/web-socket-status/index.vue';
import AuditLog from "@/main/webapp/vue/components/admin/organization-configurator/audit-log/index.vue";

// Views
import Feed from '@/main/webapp/vue/views/feed/feed.vue';
import SubmissionsView from '@/main/webapp/vue/views/submissions-view/index.vue';
import ShopsMetadataTable from "@/main/webapp/vue/components/ui/table/shops-metadata-table/index.vue";
import ProjectResponsibleUserTable
  from "@/main/webapp/vue/components/ui/table/project-responsible-user-table/index.vue";
import UserActions from '@/main/webapp/vue/components/user/user-actions/index.vue';
import { UnsupportedAttributeEntity } from '@/main/webapp/vue/model/api/UnsupportedAttributeEntity';

// required by IE11 & Microsoft Edge versions below 79
if (typeof TextEncoder !== 'function') {
  const TextEncodingPolyfill = require('text-encoding');
  window.TextEncoder = TextEncodingPolyfill.TextEncoder;
  window.TextDecoder = TextEncodingPolyfill.TextDecoder;
}

const VueMoment = require('vue-moment');
const VueScrollTo = require('vue-scrollto');

Vue.prototype.$properties = applicationConfiguration.properties;
Vue.config.productionTip = (process.env.NODE_ENV === 'production');

// Vue-leaflet this part resolve an issue where the markers would not appear
delete (Icon as any).Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.use(VueHead);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);
Vue.use(Notifications);
Vue.use(VueMoment, { moment });
Vue.use(VueScrollTo);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  error: '/img/error.gif',
  loading: '/img/loading.gif'
});
Vue.component('multiselect', Multiselect);
Vue.component('vue-editor', VueEditor);
// Use v-calendar & v-date-picker components
// Use <vc-calendar /> instead of <v-calendar />
Vue.use(VCalendar, {
  componentPrefix: 'vc'
});

const i18n: VueI18n = internationalization.createI18n({});

router.beforeEach((to: Route, from: Route, next) => {
  internationalization.initializeLanguage(i18n, to.query.lc, next);
  mapConfiguration.updateMapPropertiesToStore(to);
});

// Global components, can be used in JPSs inside a Vue instance
let registeredComponents = [
  { name: 'user-loader', component: UserLoader }, // Load the user details to the store, to be removed once we have login
  { name: 'organization-configurator', component: OrganizationConfigurator },
  { name: 'theme-configurator', component: ThemeConfigurator },
  { name: 'logo-configurator', component: LogoConfigurator },
  { name: 'module-configurator', component: ModuleConfigurator },
  { name: 'shop-configurator', component: ShopConfigurator },
  { name: 'user-configurator', component: UserConfigurator },
  { name: 'feed', component: Feed },
  { name: 'settings', component: Settings },
  { name: 'project-progress', component: ProjectProgress },
  { name: 'project-standings', component: ProjectStandings },
  { name: 'assume-user', component: AssumeUser },
  { name: 'unassume-user', component: UnassumeUser },
  { name: 'submission-metadata', component: SubmissionMetadata },
  { name: 'quick-search', component: QuickSearch },
  { name: 'item-map-container', component: ItemMapContainer },
  { name: 'shops-map-container', component: ShopsMapContainer },
  { name: 'shops-modal-map-container', component: ShopsModalMapContainer },
  { name: 'project-map-container', component: ProjectMapContainer },
  { name: 'project-modal-map-container', component: ProjectModalMapContainer },
  { name: 'upload-map-container', component: UploadMapContainer },
  { name: 'console', component: Console },
  { name: 'learning-home', component: LearningHome },
  { name: 'learning-online', component: LearningOnline },
  { name: 'entity-button', component: EntityButton },
  { name: 'workspace-switcher', component: WorkspaceSwitcher },
  { name: 'responsible-shops-popover', component: ResponsibleShopsPopover },
  { name: 'user-actions', component: UserActions },
  { name: 'submissions-view', component: SubmissionsView },
  { name: 'filter-container', component: Filter },
  { name: 'responsible-shops-popover', component: ResponsibleShopsPopover },
  { name: 'shops-metadata-table', component: ShopsMetadataTable },
  { name: 'project-responsible-user-table', component: ProjectResponsibleUserTable },
  { name: 'web-socket-request', component: WebSocketRequest },
  { name: 'web-socket-filter-request', component: WebSocketFilterRequest },
  { name: 'web-socket-import-request', component: WebSocketImportRequest },
  { name: 'project-report-request', component: ProjectReportRequest },
  { name: 'reporting-dropdown', component: ReportingDropdown },
  { name: 'web-socket-status-checker', component: WebSocketStatusChecker },
  { name: 'web-socket-status', component: WebSocketStatus },
  { name: 'audit-log', component: AuditLog }
];
for (let i = 0; i < registeredComponents.length; i++) {
  Vue.component(registeredComponents[i].name, registeredComponents[i].component);
}

registerUnusedTypes(UnsupportedAttributeEntity);

// Create Vue instances to the dom
if (document.getElementById('vue-container') !== null) {
  new Vue({
    el: '#vue-container',
    i18n,
    store,
    router,
    created() {
      this.$store.dispatch('setLocale', i18n);
    }
  });
}
