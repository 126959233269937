import { WebSocketTaskPayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskPayload";

export class ReportProjectStatus extends WebSocketTaskPayload {

  projectId: Number;
  projectStatuses: String[] = []
  filter: String;

  constructor(projectId: Number, projectStatuses: String[], filter: String) {
    super();
    this.projectId = projectId;
    this.projectStatuses = projectStatuses;
    this.filter = filter;
  }

}
