export enum WebSocketTaskType {
  CONTENT_PACKAGE = 'CONTENT_PACKAGE',
  CONTENT_REPORT = 'CONTENT_REPORT',
  RAW_DATA = 'RAW_DATA',
  CHECK_IN = 'CHECK_IN',
  PROJECT_LIST = 'PROJECT_LIST',
  PROJECT_STATUS = 'PROJECT_STATUS',
  PROJECT_USER_PERFORMANCE = 'PROJECT_USER_PERFORMANCE',
  FRAUD_DATA = 'FRAUD_DATA',
  SHOP_EXPORT = 'SHOP_EXPORT',
  SHOP_IMPORT = 'SHOP_IMPORT',
  DELETE_INACTIVE_SHOPS = 'DELETE_INACTIVE_SHOPS'
}
