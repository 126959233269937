
  import Vue, { PropType } from 'vue';
  import sharedMessages from "@/main/webapp/vue/config/internationalization/sharedMessages.json";

  import dataTableLazy from '@/main/webapp/vue/components/ui/table/lazy/index.vue';
  import {
    Field,
    FieldType,
    TableComponent,
    TablePopover
  } from '@/main/webapp/vue/components/ui/table/data/index.vue';

  import { generateLinearPathService } from "@/main/webapp/vue/services/GenerateLinearPathService";

  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { User } from "@/main/webapp/vue/model/api/User";
  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";
  import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";
  import { SearchCriteria } from "@/main/webapp/vue/model/api/SearchCriteria";
  import { SearchCriterion } from "@/main/webapp/vue/model/api/SearchCriterion";
  import { Item } from "@/main/webapp/vue/model/api/Item";
  import { NamedEntity } from "@/main/webapp/vue/model/api/NamedEntity";
  import { EntityViewMetadata } from "@/main/webapp/vue/model/api/EntityViewMetadata";

  enum FieldDataType {
    CONTENTS = "contents",
    TIMESTAMP = "timestamp",
    ID = "id",
    SUBMISSION_ID = "submission_id",
    ORGANIZATION = "organization",
    CATEGORY = "category",
    CHAIN = "chain",
    SHOP = "shop",
    PROJECT = "project",
    USER = "user",
    DEPARTMENT = "department",
    DESCRIPTION = "description"
  }

  enum FieldPriority {
    CONTENTS = 0,
    SUBMISSION_ID = 1,
    ID = 2,
    TIMESTAMP = 5,
    ORGANIZATION = 10,
    DEPARTMENT = 11,
    USER = 12,
    CHAIN = 15,
    SHOP = 16,
    PROJECT = 20,
    CATEGORIES = 30,
    DESCRIPTION = 10000 // Last!
  }

  export default Vue.extend({
    components: {
      dataTableLazy
    },
    props: {
      searchCriteria: {
        type: Object as PropType<SearchCriteria>,
        default: null
      },
      selectedSubmissionIds: {
        type: Array as PropType<number[]>,
        default: () => []
      }
    },
    data() {
      return {
        dynamicallyAddedFields: [] as Field[],
        fields: [
          {
            key: "contents",
            labelHidden: true,
            active: true,
            priority: FieldPriority.CONTENTS,
            narrow: true,
            type: FieldType.POPOVER,
            entityType: FieldDataType.CONTENTS,
            popover: {
              icon: (contents: ItemContent[]) => {
                return contents && contents.length > 0 ? contents.length === 1 ? 'picture' : 'pictures' : 'none-color-swatch';
              },
              iconLink: (rowData: any) => {
                let itemView: NavigationLink | undefined =
                  NavigationLinks.getLinkFromNavigation(NavigationLinkType.WEB, rowData.item.nav);
                if (itemView) {
                  const params: string = this.prepareParams(rowData, itemView);
                  window.location.href = `${itemView.href}${params}`;
                }
              },
              placement: "right",
              wide: true,
              trigger: "hover",
              component: TableComponent.CONTENT_CONTAINER,
              content: (contents: ItemContent[]) => {
                return {
                  contents: contents,
                  enableCollapse: false
                };
              }
            } as TablePopover
          },
          {
            key: "submissionId",
            label: "", // Disable for now, since it is not being mapped properly. this.$t('entity.id.submission'),
            type: FieldType.TEXT,
            entityType: FieldDataType.SUBMISSION_ID,
            active: false,
            priority: FieldPriority.SUBMISSION_ID
          },
          {
            key: "id",
            label: this.$t('generic.id.internal'),
            type: FieldType.TEXT,
            entityType: FieldDataType.ID,
            active: false,
            priority: FieldPriority.ID
          },
          {
            key: "created",
            label: this.$t('generic.timestamp'),
            type: FieldType.TIMESTAMP_LEGACY,
            entityType: FieldDataType.TIMESTAMP,
            active: true,
            priority: FieldPriority.TIMESTAMP
          },
          {
            key: FieldDataType.ORGANIZATION,
            path: "itemMetadata",
            label: this.$tc('entity.name.organization'),
            type: FieldType.COMPONENT,
            entityType: FieldDataType.ORGANIZATION,
            active: false,
            priority: FieldPriority.ORGANIZATION,
            component: TableComponent.SUBMISSION_METADATA_ELEMENT,
            content: (itemMetadata: any): PropType<HierarchicalEntity> | null => {
              return this.mapSubmissionMetadataElementToComponentProp(FieldDataType.ORGANIZATION, itemMetadata);
            }
          },
          {
            key: "author.department",
            label: this.$tc('entity.name.department'),
            type: FieldType.COMPONENT,
            entityType: FieldDataType.DEPARTMENT,
            active: false,
            priority: FieldPriority.DEPARTMENT,
            component: TableComponent.SUBMISSION_METADATA_ELEMENT,
            content: (department: NamedEntity): PropType<NamedEntity> => {
              return {
                element: department,
                showOnlyValue: true
              } as PropType<NamedEntity>;
            }
          },
          {
            key: "author",
            label: this.$tc('entity.name.user'),
            type: FieldType.COMPONENT,
            entityType: FieldDataType.USER,
            active: true,
            priority: FieldPriority.USER,
            component: TableComponent.SUBMISSION_METADATA_ELEMENT,
            content: (author: User): PropType<NamedEntity> => {
              return {
                element: author,
                showOnlyValue: true
              } as PropType<NamedEntity>;
            },
            formatter: (value: User): any => {
              if (value) {
                return value;
              }
              let unregistered: NamedEntity = new NamedEntity();
              unregistered.name = this.$t('generic.unregistered-user') as string;
              unregistered.metadata = new EntityViewMetadata();
              return unregistered;
            }
          },
          {
            key: FieldDataType.CHAIN,
            path: "itemMetadata",
            label: this.$tc('entity.name.chain'),
            type: FieldType.COMPONENT,
            entityType: FieldDataType.CHAIN,
            active: true,
            priority: FieldPriority.CHAIN,
            component: TableComponent.SUBMISSION_METADATA_ELEMENT,
            content: (itemMetadata: any): PropType<HierarchicalEntity> | null => {
              return this.mapSubmissionMetadataElementToComponentProp(FieldDataType.CHAIN, itemMetadata);
            }
          },
          {
            key: FieldDataType.SHOP,
            path: "itemMetadata",
            label: this.$tc('entity.name.shop'),
            type: FieldType.COMPONENT,
            entityType: FieldDataType.SHOP,
            active: true,
            priority: FieldPriority.SHOP,
            component: TableComponent.SUBMISSION_METADATA_ELEMENT,
            content: (itemMetadata: any): PropType<HierarchicalEntity> | null => {
              return this.mapSubmissionMetadataElementToComponentProp(FieldDataType.SHOP, itemMetadata);
            }
          },
          {
            key: FieldDataType.PROJECT,
            path: "itemMetadata",
            label: this.$tc('entity.name.project'),
            type: FieldType.COMPONENT,
            entityType: FieldDataType.PROJECT,
            active: true,
            priority: FieldPriority.PROJECT,
            component: TableComponent.SUBMISSION_METADATA_ELEMENT,
            content: (itemMetadata: any): PropType<HierarchicalEntity> | null => {
              return this.mapSubmissionMetadataElementToComponentProp(FieldDataType.PROJECT, itemMetadata);
            }
          },
          {
            key: FieldDataType.DESCRIPTION,
            label: this.$t('generic.description'),
            labelHidden: true,
            active: true,
            priority: FieldPriority.DESCRIPTION,
            narrow: true,
            type: FieldType.POPOVER,
            entityType: FieldDataType.DESCRIPTION,
            popover: {
              icon: "chat"
            } as TablePopover
          }
        ] as Field[]
      };
    },
    watch: {
      searchCriteria: {
        deep: true,
        handler: function(newSearchCriteria: SearchCriteria, oldSearchCriteria: SearchCriteria) {
          if (newSearchCriteria && newSearchCriteria.results) {
            this.submissionsLoadedFromFilter(newSearchCriteria.results.list);
          }
        }
      }
    },
    computed: {
      prefilledData(): Item[] {
        if (this.searchCriteria && this.searchCriteria.results && this.searchCriteria.results.list) {
          this.submissionsLoaded(this.searchCriteria.results.list);
          return this.searchCriteria.results.list;
        }
        return [];
      },
      navigationLink(): NavigationLink {
        if (this.searchCriteria && this.searchCriteria.results && this.searchCriteria.results.nav) {
          let nextLink: NavigationLink | undefined = this.searchCriteria.results.nav.getLink(NavigationLinkType.PAGE_NEXT);
          if (nextLink) {
            return nextLink;
          }
        }
        return new NavigationLink();
      }
    },
    methods: {
      prepareParams(data: any, nav: NavigationLink): string {
        return generateLinearPathService.prepareParams(nav.href, data.index);
      },
      fieldStatusChanged(field: Field): void {
        (this as any).$emit("list-view-fields-changed", this.fields);
      },
      submissionsSelected(submissions: Item[]): void {
        (this as any).$emit("list-view-submissions-selected", submissions);
      },
      submissionsLoadedFromFilter(submissions: Item[] | undefined): void {
        this.dynamicallyAddedFields.forEach((field: Field) => {
          field.active = false;
        });
        this.submissionsLoaded(submissions);
      },
      submissionsLoaded(submissions: Item[] | undefined): void {
        let filterCriterionCategories: SearchCriterion[] =
          this.findFilterCriterionWithEntityType(this.searchCriteria, FieldDataType.CATEGORY);

        for (let submission of (submissions || [])) {
          this.mapSubmissionMetadataToFields(FieldDataType.CATEGORY, submission.itemMetadata || [],
                                             (filterCriterionCategories || []).flatMap((criterion: SearchCriterion) => criterion.selectedDataValues) || [], FieldPriority.CATEGORIES);
        }
      },
      findFilterCriterionWithEntityType(criteria: SearchCriteria | undefined, type: string): SearchCriterion[] {
        return (criteria?.criteria || []).filter((criterion: SearchCriterion) => criterion.definition?.entity === type && criterion.selectedDataValues && criterion.selectedDataValues.length > 0);
      },
      mapSubmissionMetadataToFields(type: string, submissionMetadata: HierarchicalEntity[], filterEntityIds: (string | undefined)[], priority: number = 0): void {
        for (let metadataElement of submissionMetadata) {
          if (!metadataElement.metadata || metadataElement.metadata.type === type) {
            if (metadataElement.metadata && metadataElement.metadata.hierarchical && metadataElement.metadata.hierarchical.ignoreRoot) {
              this.mapSubmissionMetadataToFields(type, (metadataElement.children || []), filterEntityIds, priority);
            } else {
              if (metadataElement.children?.length === 1 && metadataElement.children[0].children) {
                let child: HierarchicalEntity = metadataElement.children[0];
                child.metadata = metadataElement.metadata;
                this.mapSubmissionMetadataElementToField(type, child, filterEntityIds, (+metadataElement.priority + priority + 1));
              } else {
                this.mapSubmissionMetadataElementToField(type, metadataElement, filterEntityIds, priority);
              }
            }
          }
        }
      },
      mapSubmissionMetadataElementToField(type: string, metadataElement: HierarchicalEntity, filterEntityIds: (string | undefined)[], priority: number = 0): void {
        if (metadataElement.name && metadataElement.children && metadataElement.children.length > 0) {
          let key: string = `${type}_${metadataElement.name}`;
          let registeredField: Field | undefined = this.fieldRegistered(key);
          if (!registeredField) {
            let newField: Field = {
              key: key,
              path: "itemMetadata",
              label: metadataElement.name,
              type: FieldType.COMPONENT,
              entityType: type,
              entityId: String(metadataElement.id),
              group: this.$tc(`entity.name.${type}`, 0),
              active: filterEntityIds.length === 0 || filterEntityIds.includes(String(metadataElement.id)),
              priority: metadataElement.priority ? (+metadataElement.priority + priority) : priority,
              component: TableComponent.SUBMISSION_METADATA_ELEMENT,
              content: (itemMetadata: any): PropType<HierarchicalEntity> | null => {
                return this.mapSubmissionMetadataElementToComponentProp(type, itemMetadata, metadataElement.name);
              }
            } as Field;

            this.fields.push(newField);
            this.dynamicallyAddedFields.push(newField);

            (this as any).$emit("list-view-fields-changed", this.fields);
          }
        }
      },
      mapSubmissionMetadataElementToComponentProp(entityType: string, itemMetadata: HierarchicalEntity[], name?: string | undefined): PropType<HierarchicalEntity> | null {
        for (let metadataElement of (itemMetadata || [])) {
          if (!metadataElement.metadata || metadataElement.metadata.type === entityType) {
            if (name === undefined || (!metadataElement || (metadataElement.name && metadataElement.name === name))) {
              return {
                element: metadataElement,
                showOnlyValue: true
              } as PropType<HierarchicalEntity>;
            }

            if (metadataElement?.children?.length > 0) {
              let match: PropType<HierarchicalEntity> | null =
                this.mapSubmissionMetadataElementToComponentProp(entityType, (metadataElement.children || []), name);
              if (match) {
                return match;
              }
            }
          }
        }
        return null;
      },
      fieldRegistered(key: string): Field | undefined {
        return (this.fields || []).find((field: Field) => field.key === key);
      },
      setFieldsInGroupInactive(group: string): void {
        return (this.fields || []).filter((field: Field) => field.group === group).forEach((field: Field) => { field.active = false; });
      }
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
