
export class WebSocketTaskPayload {

  binary: boolean = false;
  extraData: Map<string, string> | null = null;

  public addExtraData(key: string, value: string): void {
    if (this.extraData === null) {
      this.extraData = new Map<string, string>();
    }
    this.extraData.set(key, value);
  }
}

