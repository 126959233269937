import { webSocketService } from "./WebSocketService";
import { frameCallbackType, messageCallbackType } from "@stomp/stompjs";
import { WebSocketRequest } from "@/main/webapp/vue/model/api/web-socket/WebSocketRequest";
import { WebSocketRequestType } from "@/main/webapp/vue/model/api/web-socket/WebSocketRequestType";

export class WebSocketIntegrationService {

  public static active(): boolean {
    return webSocketService.active();
  }

  public static connect(onConnectCallback: frameCallbackType, onDisconnectCallback: frameCallbackType, onStompErrorCallback: frameCallbackType): void {
    return webSocketService.connect(onConnectCallback, onDisconnectCallback, onStompErrorCallback);
  }

  public static disconnect(): void {
    return webSocketService.disconnect();
  }

  public static subscribe(websocketDestination: string, onSubscribeCallback: messageCallbackType): void {
    return webSocketService.subscribe(`${WebSocketRequestType.SUBSCRIPTIONS}${websocketDestination}`, onSubscribeCallback);
  }

  public static unsubscribe(websocketDestination: string): void {
    return webSocketService.unsubscribe(`${WebSocketRequestType.SUBSCRIPTIONS}${websocketDestination}`);
  }

  public static jsonRequest(webSocketRequest: WebSocketRequest): void {
    return webSocketService.jsonRequest(webSocketRequest);
  }

  public static binaryRequest(webSocketRequest: WebSocketRequest): void {
    return webSocketService.binaryRequest(webSocketRequest);
  }
}
