import { WebSocketTaskType } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskType";

export class WebSocketTaskDefinition {

  webSocketTaskType: WebSocketTaskType;
  destination: string;
  cancelDestination: string;
  filetype?: string;
  timeout: number = -1;
  maxItemCondition: number = -1;


  constructor(webSocketTaskType: WebSocketTaskType,
              destination: string,
              cancelDestination: string,
              filetype: string = "",
              timeout: number = -1,
              maxItemCondition: number = -1) {
    this.webSocketTaskType = webSocketTaskType;
    this.destination = destination;
    this.cancelDestination = cancelDestination;
    this.filetype = filetype;
    this.timeout = timeout;
    this.maxItemCondition = maxItemCondition;
  }
}
