
  import Vue, { PropType } from 'vue';

  import bulkShareContainer from "@/main/webapp/vue/components/ui/share/bulk-share-container/index.vue";
  import slideShowContainer from "@/main/webapp/vue/components/ui/content/slide-show-container/index.vue";
  import { WebSocketTaskType } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskType";
  import webSocketRequest from "@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/index.vue";
  import webSocketFilterRequest
    from "@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/web-socket-filter-request/index.vue";
  import reportingDropdown from "@/main/webapp/vue/components/web-socket/reporting/reporting-dropdown/index.vue";

  import { SearchCriteriaSelectedDataValue } from "@/main/webapp/vue/model/api/SearchCriteriaSelectedDataValue";
  import { Field } from "@/main/webapp/vue/components/ui/table/data/index.vue";

  export default Vue.extend({
    components: {
      bulkShareContainer,
      slideShowContainer,
      reportingDropdown,
      webSocketRequest,
      webSocketFilterRequest
    },
    props: {
      isListView: {
        type: Boolean,
        required: false
      },
      searchCriteriaSelectedDataValues: {
        type: Array as PropType<SearchCriteriaSelectedDataValue[]>,
        required: false
      },
      selectedSubmissionIds: {
        type: Array as PropType<number[]>,
        default: () => []
      },
      listViewSelectedFields: {
        type: Array as PropType<Field[]>,
        required: false
      },
      showShareContainer: {
        type: Boolean,
        default: false
      },
      showSlideContainer: {
        type: Boolean,
        default: false
      },
      showReportDropdown: {
        type: Boolean,
        default: false
      },
      contentPackageEnabled: {
        type: Boolean,
        default: false
      },
      contentReportEnabled: {
        type: Boolean,
        default: false
      },
      rawDataReportEnabled: {
        type: Boolean,
        default: false
      },
      checkInReportEnabled: {
        type: Boolean,
        default: false
      },
      fraudDataReportEnabled: {
        type: Boolean,
        default: false
      },
      shopExportEnabled: {
        type: Boolean,
        default: false
      },
      itemCount: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        WebSocketTaskType
      };
    }
  });
