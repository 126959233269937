
  import Vue from 'vue';

  export default Vue.extend({
    props: {
      small: {
        type: Boolean,
        default: true
      },
      variant: {
        type: String,
        default: "dark"
      }
    }
  });
